body{
  font-family: 'PT Sans', sans-serif;
  margin: 0;
  background-color: #F7F7F7;
}
* {
  font-size: large;
}
.title{
  font-size: 3rem;
  text-align: center;
}
.Nav {
    background-color: #3B3939;
    border-bottom: #707070 1px solid;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
.Nav ul{
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
}
.Nav a{
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
  height: 100%;
  box-sizing: border-box;
}
.Nav a.icon{
  background: #272525;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
.logo{
  width: fit-content;
  font-weight: 900;
}
.LogoBurger{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Burger{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
.OpenNav{
  display: flex;
  flex-direction: column;
}
.ClosedNav{
  display: none
}
.active {
  background-color: #111;
  color: white;
}
.UserInfo{
    margin-left: auto;
    margin-right: 1rem;
    display: flex;
}
.UserInfo>p{
  margin-right: 1rem;
  color: white;
}

  @media only screen and (min-width: 768px) {
    .Burger{
      display: none;
    }
    .Nav .OpenNav, .Nav .ClosedNav{
      display: flex;
      flex-direction: row;
    }
    .Nav{
      display: flex;
      z-index: 1;
    }
    .Nav .NavLinks{
      display: flex;
      flex-direction: row;
      z-index: 9;
    }
    .Nav a.icon{
      display: none;
    }
  }